<template>
  <panda-layout></panda-layout>
</template>

<script>
import PandaLayout from '@/components/layout/PandaLayout'

export default {
  name: 'Home',
  components: {
    PandaLayout,
  },
  watch: {},
  computed: {},
  methods: {},
  async created() {},
}
</script>
