<template>
  <div class="wrapper main">
    <div class="container">
      <router-view></router-view>
      <!-- <main-footer ref="main-footer"></main-footer> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  watch: {},
  methods: {},
}
</script>

<style></style>
